import './Footer.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Footer = (props: any) => {
    return (
        <>
            <Row className="bg-light footer rounded">
                <Col className="col-12">
                    <Row>
                        <Col className="col-6 footer-column">
                            <h6>INFORMATION</h6>
                            <p><b>BIER.</b> Auf dieser Website werden ganz unkompliziert aktuelle
                                Bierangebote angezeigt, sodass man ganz einfach und direkt sehen kann,
                                welches kostbare Bier man sich als nächstes gönnen kann.
                            </p>
                            <pre>Wird betrieben von: Felix Brüning</pre>
                        </Col>
                        <Col className="col-4 footer-column">
                            <h6>Ein Unternehmen der <i>Bier Promotions International</i></h6>
                            <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" target="_blank" rel="noopener noreferrer">Karriere</a>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default Footer;