import 'bootstrap/dist/css/bootstrap.css';
import './Main.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Footer from './Footer';
import { Table } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

/**
 * Item specification of a Beer element
 */
interface ItemProperties {
  id: number;
  store: string;
  name: string;
  price: string;
  amount: string;
  info: string;
}

const BACKEND_URL='https://api.bier-angebote.de/beer'

/**
 * The Main page.
 * 
 * @param props 
 * @returns 
 */
const Main = (props: any) => {

  const [data, setData] = useState<ItemProperties[]>([]);
  const [show, setShow] = useState(false);
  const [zipCode, setZipCode] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  function loadData(zipCode:number) {
    setData([]);
    var fetchStr:string = BACKEND_URL;
    if ( zipCode > 10000 && zipCode < 99999 ) {
      fetchStr += "?zip="+zipCode;
    }
    fetch(fetchStr, { 
      mode:'cors', 
      method: 'GET', 
      headers: { 'Content-Type': 'application/json' }, }
    )
    .then(response => response.json())
    .then(data => {
      var dataSize:number = Object.keys(data).length;
      var beers:ItemProperties[] = new Array(dataSize);

      for ( var i = 0; i < dataSize; i++ ) {
        var element = data[i.toString()];
        var item:ItemProperties = {
          id: i+1,
          store: element["store"],
          name: element["name"],
          price: element["price"],
          amount: element["amount"],
          info: element["info"]
        }

        beers[i] = item;
      }

      console.log("Data Loaded.");
      setData(beers);
    })
    .catch(error => {
      console.log('There was an error', error);
      handleShow();
    });
  }

  useEffect(() => {
    if ( ! dataLoaded ) {
      loadData(zipCode);
      setDataLoaded(true);
    } // eslint-disable-next-line
  }, [dataLoaded === false]);

  function inputZipCodeCallback(event:any) {
    console.log(event.target.value);
    var zip:number = event.target.value 

    if ( (zip > 10000 && zip < 99999) || zip === 0 ) {
      setZipCode(zip);
    }
  }

  function btnRefreshCallback(event:any) {
    setDataLoaded(false);
  }

  function inputZipEnterCallback(event:any) {
    console.log("Enter")
  }

  return (
    <>
    <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Mist, hier ist wohl ein Problem!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Ahhhhh Mist, hier ist ein Problem! Entweder bastelt der Chef hier gerade wieder herum, oder 
            es ist etwas ernstes. Wenn es in 10 Minuten nicht klappen sollte, dann rufe bitte Felix an!

            Danke.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Schließen
            </Button>
          </Modal.Footer>
        </Modal>
        <Container fluid className='MainPage'>
          <Row className='header-row'>
            <Col md='2' className='logo-col'>
              <img src="bierangebotelogo.jpg" alt='Logo!' className='bierglas-main' />
            </Col>
            <Col md='10'>
              <h1>Bier-Angebote.de</h1>
            </Col>
          </Row>
          <Row className='zip-row'>
            <Col md='1' className=''>
              <Form>
                  <Form.Group className="mb-3" controlId="zipInput2">
                    <Form.Label>PLZ:</Form.Label>
                  </Form.Group>
              </Form>
            </Col>
            <Col md='1' className=''>
              <Form>
                <Form.Group className="mb-3" controlId="zipInput">
                  <Form.Control type="postleitzahl" placeholder="25596" onChange={inputZipCodeCallback} 
                      onSubmit={inputZipEnterCallback} />
                </Form.Group>
              </Form>
            </Col>
            <Col md='3' className=''>
              <Button variant="success" onClick={btnRefreshCallback}>Aktualisieren</Button>
            </Col>
          </Row>
          <Row className='content-row'>
            <Col md='12'>
              <div className="table-wrapper-scroll-y table-scrollbar">
                <Table className='table' responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Geschäft</th>
                      <th>Bier</th>
                      <th>Preis</th>
                      <th>Menge</th>
                      <th>Info's</th>
                    </tr>
                  </thead>
                  <tbody> 
                    {
                      data.map( (e:any) => 
                      <>
                        <tr key={ e.id }> 
                          <td>{ e.id }</td>
                          <td>{ e.store }</td> 
                          <td>{ e.name }</td>  
                          <td>{ e.price }€</td> 
                          <td>{ e.amount }</td> 
                          <td>{ e.info }</td> 
                        </tr>
                      </>)
                    }
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
          <Footer></Footer>
        </Container>
    </>
  );
};

// 
export default Main;
